<template lang="pug">
.flex.justify-between
  .back(@click="$emit('back')")
    svgIcon.icon(:path="mdiChevronLeft", type="mdi", :size="24")
    | Volver
  .cta(
    @click="!disableNext && $emit('next')",
    :class="{ disabled: disableNext }"
  ) Continuar
    svgIcon.icon(:path="mdiChevronRight", type="mdi", :size="24")
</template>
<script>
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
export default {
  emits: ["back", "next"],
  props: {
    disableNext: {
      type: Boolean,
      default: false,
    },
  },
  name: "buttons-back-next.wizard",
  data: () => ({
    mdiChevronRight,
    mdiChevronLeft,
  }),
};
</script>
<style lang="scss" scoped>
.back {
  @apply pl-3 pr-6 py-2 rounded-lg flex items-center bg-white text-gray-400 font-medium border cursor-pointer;

  .icon {
    @apply mr-2;
  }

  &:hover .icon {
    animation: slide-left 0.25s ease-in-out;
  }
}

.cta {
  @apply pr-3 pl-6 py-2 rounded-lg bg-green-600 font-medium text-white flex items-center transition-shadow shadow-none cursor-pointer;

  .icon {
    @apply ml-2;
  }

  &:hover {
    box-shadow: 0 10px 15px -5px rgba(132, 204, 22, 0.2),
      0 5px 10px -5px rgba(132, 204, 22, 0.1);
    .icon {
      animation: slide-right 0.25s ease-in-out;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover .icon {
      animation: none;
    }
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(15px) scale(0.5);
    opacity: 0.1;
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(-15px) scale(0.5);
    opacity: 0.1;
  }
}
</style>