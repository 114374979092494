<template lang="pug">
.info-box
  svgIcon(:path="mdiInformation", type="mdi", :size="18")
  p {{ infoText }}
</template>

<script>
import { mdiInformation } from "@mdi/js";

export default {
  props: {
    infoText: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    mdiInformation,
  }),
};
</script>
<style lang="scss" scoped>
.info-box {
  @apply py-2 text-sm rounded-xl my-1 flex items-center;
  svg {
    @apply text-green-600 mr-2;
  }
}
</style>