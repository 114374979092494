<template lang="pug">
.box-option(:class="{ selected: selected }")
  img(:src="image", width="60", height="60")
  svgIcon.check(
    :path="mdiCheckBold",
    type="mdi",
    :size="24",
    v-show="selected"
  )
  .title-option {{ name }}
</template>
<script>
import { mdiCheckBold } from "@mdi/js";
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiCheckBold,
  }),
  setup(props) {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.box-option {
  @apply flex flex-col justify-center items-center bg-green-50 border-2 border-green-100 rounded-xl py-4 cursor-pointer opacity-60 relative;

  svg,
  img {
    @apply mb-1 text-green-700;
  }

  &:hover {
    transform: scale(1.05);
    @apply opacity-80;
  }

  .title-option {
    @apply font-bold text-green-800;
  }

  &.selected {
    @apply border-green-600 opacity-100;
  }

  .check {
    position: absolute;
    top: 0.3rem;
    right: 0.5rem;
    @apply text-green-500 bg-white rounded-full;
  }
}
</style>